import React, { useState, useEffect } from "react"
import HomeLayout from "../layouts/HomeLayout"

import { Grid, Paper } from "@material-ui/core"
import { Link } from "gatsby"
import DatePicker from "react-datepicker"
import InputAdornment from '@material-ui/core/InputAdornment';
import "react-datepicker/dist/react-datepicker.css"

import moment from "moment"

const Fixed = () => {
  const [investment, setInvestment] = useState(Number)

  return (
    <Paper
      style={{
        background: "rgb(104,102,233)",
        background:
          "linear-gradient(0deg, rgba(104,102,233,1) 0%, rgba(106,165,227,1) 100%)",
        height: "100%",
        padding: 20,
        width: "80%",
      }}
    >
      <div class="form-group row">
        <label
          for="inputEmail3"
          class="col-sm-5 col-form-label"
          style={{ color: "#fff" }}
        >
          Capital Investment
        </label>
        <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="basic-addon1"
                style={{ backgroundColor: "#FFF" }}
              >
                ₹
              </span>
            </div>
        <div class="col-sm-4">
          <input
            type="email"
            class="form-control"
            id="inputEmail3"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={investment}
            style={{
              background: "transparent",
              color: "#FFF",
            }}
            onChange={e => {
              setInvestment(e.target.value)
            }}
          />
        </div>
      </div>
      <p
        style={{
          fontSize: 26,
          color: "#FFF",
        }}
      >
        Payment Options
      </p>
      <div class="form-group row">
        <label
          for="inputEmail3"
          class="col-sm-5 col-form-label"
          style={{ color: "#fff" }}
        >
          Monthly
        </label>
        <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="basic-addon1"
                style={{ backgroundColor: "#FFF" }}
              >
                ₹
              </span>
            </div>
        <div class="col-sm-4">
          <input
            type="email"
            class="form-control"
            disabled
            id="inputEmail3"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            style={{
              background: "transparent",
              color: "#FFF",
            }}
            value={investment * 0.02}
          />
        </div>
      </div>{" "}
      <div class="form-group row">
        <label
          for="inputEmail3"
          class="col-sm-5 col-form-label"
          style={{ color: "#fff" }}
        >
          Quaterly
        </label>
        <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="basic-addon1"
                style={{ backgroundColor: "#FFF" }}
              >
                ₹
              </span>
            </div>
        <div class="col-sm-4">
          <input
            type="email"
            class="form-control"
            id="inputEmail3"
            disabled
            style={{
              background: "transparent",
              color: "#FFF",
            }}
            value={investment * 0.07}
          />
        </div>
      </div>{" "}
      <div class="form-group row">
        <label
          for="inputEmail3"
          class="col-sm-5 col-form-label"
          style={{ color: "#fff" }}
        >
          Double (24 months)
        </label>
        <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="basic-addon1"
                style={{ backgroundColor: "#FFF" }}
              >
                ₹
              </span>
            </div>
        <div class="col-sm-4">
          <input
            type="email"
            disabled
            class="form-control"
            id="inputEmail3"
            style={{
              background: "transparent",
              color: "#FFF",
            }}
            value={investment * 2}
          />
        </div>
      </div>
    </Paper>
  )
}

const Monthly = () => {
  const [monthlyDeposit, setMonthlyDeposit] = useState(0.0)
  const [investedDate, setInvestedDate] = useState("")
  const [addedDate, setAddedDate] = useState("")

  useEffect(
    () =>
      setAddedDate(
        moment(investedDate, "DD-MM-YYYY")
          .add("21", "months")
          .format("DD-MM-YYYY")
      ),
    [investedDate]
  )
  const handleChange = date => {
    setInvestedDate(date)
  }
  return (
    <Paper
      style={{
        background: "rgb(104,102,233)",
        background:
          "linear-gradient(0deg, rgba(104,102,233,1) 0%, rgba(106,165,227,1) 100%)",
        height: "100%",
        padding: 20,
        width: "80%",
      }}
    >
      <div class="form-group row">
        <label
          for="inputEmail3"
          class="col-sm-5 col-form-label"
          style={{ color: "#fff" }}
        >
          Monthly deposit
        </label>
        <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="basic-addon1"
                style={{ backgroundColor: "#FFF" }}
              >
                ₹
              </span>
            </div>
        <div class="col-sm-4">
          
          <input
            type="email"
            class="form-control"
            id="inputEmail3"
            style={{
              background: "transparent",
              color: "#FFF",
            }}
            onChange={e => {
              setMonthlyDeposit(e.target.value)
            }}
          />
        </div>
      </div>
      <p
        style={{
          fontSize: 23,
          color: "#FFF",
        }}
      >
        Systematic investment plans (20 months)
      </p>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          {/* <div class="form-group row">
            <label
              for="inputEmail3"
              class="col-sm-5 col-form-label"
              style={{ color: "#fff" }}
            >
              Invested date
            </label>
            <div class="col-sm-4">
              <input
                type="email"
                placeholder="dd-mm-yyyy"
                class="form-control"
                id="inputEmail3"
                style={{
                  background: "transparent",
                }}
                value={investedDate}
                onChange={async e => {
                  setInvestedDate(e.target.value)
                }}
              />
            </div>
          </div> */}

          <div class="form-group row">
            <label
              for="inputEmail3"
              class="col-sm-5 col-form-label"
              style={{ color: "#fff" }}
            >
              Invested date
            </label>
            <div class="col-sm-4">
              <DatePicker selected={investedDate} onChange={handleChange} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div class="form-group row">
            <label
              for="inputEmail3"
              class="col-sm-5 col-form-label"
              style={{ color: "#fff" }}
            >
              21st Month
            </label>
            <div class="col-sm-4">
              <input
                type="email"
                disabled
                class="form-control"
                id="inputEmail3"
                style={{
                  background: "transparent",
                  color: "#FFF",
                }}
                value={addedDate}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div class="input-group ">
            <label
              for="inputEmail3"
              class="col-sm-5 col-form-label"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              Maturity payment
            </label>
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="basic-addon1"
                style={{ backgroundColor: "#FFF" }}
              >
                RS
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              disabled
              aria-label="Username"
              aria-describedby="basic-addon1"
              style={{ backgroundColor: "transparent", color: "#FFF" }}
              value={monthlyDeposit * 20 * 1.3}
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}
const Calculator = () => {
  return (
    <HomeLayout>
      <div style={{ padding: 20, width: "100%" }}>
        <Grid container spacing={0} align="center" justify="center">
          <Grid item xs={12}>
            <p
              style={{
                fontSize: 26,
                color: "#6AA5E3",
                fontWeight: "bold",
              }}
            >
              Plan Calculator
            </p>
            <br />
          </Grid>
          <Grid item xs={12}>
            <div className="row">
              {[
                {
                  heading: "Fixed Deposit",
                  component: Fixed(),
                },
                {
                  heading: "Monthly Deposit",
                  component: Monthly(),
                },
              ].map(slide => {
                return (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    xl={6}
                    md={6}
                    sm={6}
                    style={{
                      width: "100%",
                      paddingBottom: 100,
                    }}
                  >
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{
                        backgroundColor: "#E51C21",
                        borderColor: "#E51C21",
                        borderRadius: 20,
                        width: 200,
                        color: "#FFF",
                        height: 40,
                        fontWeight: "bold",
                        marginBottom: 20,
                      }}
                    >
                      {slide.heading}
                    </button>
                    {slide.component}
                  </Grid>
                )
              })}
            </div>
          </Grid>
        </Grid>
      </div>
    </HomeLayout>
  )
}

export default Calculator
